import React, { useState } from 'react';
import styled from 'styled-components';
import { FaChevronCircleRight, FaChevronCircleLeft } from 'react-icons/fa';
import GalleryItem from './GalleryItem';
import { S3File } from '../Gallery'; // Ensure this is correctly typed in TypeScript
import { Icon } from '@/components/StyledComponents';

interface GalleryCarouselProps {
  files: S3File[];
  selectedAlbum: string;
}

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const GalleryCarousel: React.FC<GalleryCarouselProps> = ({
  files,
  selectedAlbum,
}) => {
  const [albumIndexes, setAlbumIndexes] = useState<{
    [albumName: string]: number;
  }>({});

  const nextSlide = () => {
    setAlbumIndexes(prev => {
      const currentIndex = prev[selectedAlbum] || 0;
      const newIndex = (currentIndex + 1) % files.length;
      return { ...prev, [selectedAlbum]: newIndex };
    });
  };

  const prevSlide = () => {
    setAlbumIndexes(prev => {
      const currentIndex = prev[selectedAlbum] || 0;
      const newIndex = (currentIndex - 1 + files.length) % files.length;
      return { ...prev, [selectedAlbum]: newIndex };
    });
  };

  const currentIndex = albumIndexes[selectedAlbum] || 0;

  return (
    <CarouselContainer>
      <Icon onClick={prevSlide}>
        <FaChevronCircleLeft />
      </Icon>
      {files.length > 0 && files[currentIndex] && (
        <GalleryItem file={files[currentIndex]} />
      )}
      <Icon onClick={nextSlide}>
        <FaChevronCircleRight />
      </Icon>
    </CarouselContainer>
  );
};

export default React.memo(GalleryCarousel);
