import React, { useState } from 'react';
import styled from 'styled-components';
import { FaChevronCircleRight, FaChevronCircleLeft } from 'react-icons/fa';

import { MEDIA_BREAKPOINTS } from '@/constants';

import { Icon } from '@/components/StyledComponents';
import HorizontalScrollWrapper from '@/hooks/HorizontalScrollWrapper';

import { Album } from '../Gallery';
import AlbumItem from './AlbumItem';

interface AlbumsCarouselProps {
  albums: Album[];
  selectAlbum: (albumName: string) => void;
}

const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const CarouselButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit; // Use the inherited font color

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:hover:not(:disabled) {
    color: #aaa; // Change hover color
  }

  @media (max-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    display: none;
  }
`;

const AlbumsCarousel: React.FC<AlbumsCarouselProps> = ({
  albums,
  selectAlbum,
}) => {
  const [startIndex, setStartIndex] = useState(0);
  const [selectedAlbum, setSelectedAlbum] = useState<string | null>('חתונות');

  const itemsPerPage = window.innerWidth <= 768 ? albums.length : 5;

  const nextAlbum = () => {
    setStartIndex(prevIndex =>
      Math.min(prevIndex + 1, albums.length - itemsPerPage),
    );
  };

  const prevAlbum = () => {
    setStartIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const handleSelectAlbum = (albumName: string) => {
    setSelectedAlbum(albumName);
    selectAlbum(albumName);
  };

  return (
    <CarouselContainer>
      <CarouselButton onClick={prevAlbum} disabled={startIndex === 0}>
        <Icon>
          <FaChevronCircleLeft />
        </Icon>
      </CarouselButton>
      <HorizontalScrollWrapper>
        {albums
          .slice(startIndex, startIndex + itemsPerPage)
          .map((album, index) => (
            <AlbumItem
              key={index}
              album={album}
              selectAlbum={handleSelectAlbum}
              isSelected={selectedAlbum === album.albumName}
            />
          ))}
      </HorizontalScrollWrapper>
      <CarouselButton
        onClick={nextAlbum}
        disabled={startIndex >= albums.length - itemsPerPage}
      >
        <Icon>
          <FaChevronCircleRight />
        </Icon>
      </CarouselButton>
    </CarouselContainer>
  );
};

export default React.memo(AlbumsCarousel);
