import React from 'react';
import { GoogleMap, useJsApiLoader, Libraries } from '@react-google-maps/api';
import styled from 'styled-components';
import { FormButton } from '@/components/StyledComponents';

import { MEDIA_BREAKPOINTS } from '@/constants';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const MapContainer = styled.div`
  margin: 5px;
  width: ${({ theme }) => theme.sizes.small.mapSize};
  height: ${({ theme }) => theme.sizes.small.mapSize};

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    width: ${({ theme }) => theme.sizes.medium.mapSize};
    height: ${({ theme }) => theme.sizes.medium.mapSize};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    width: ${({ theme }) => theme.sizes.large.mapSize};
    height: ${({ theme }) => theme.sizes.large.mapSize};
  }
`;

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const center = {
  lat: 31.25883,
  lng: 34.78095,
};
const libraries: Libraries = ['places'];
const language = 'iw'; 

const MapComponent: React.FC = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleMapsApiKey as string,
    libraries: libraries,
    language: language,
  });

  const navigate = () => {
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${center.lat},${center.lng}`;
    window.open(googleMapsUrl, '_blank');
  };

  return (
    <FlexContainer>
      <MapContainer>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: '100%',
            }}
            center={center}
            zoom={15}
          ></GoogleMap>
        )}
      </MapContainer>
      <FormButton onClick={navigate}>נווט אליי</FormButton>
    </FlexContainer>
  );
};

export default MapComponent;
