import React from 'react';
import styled from 'styled-components';
import { LargeImage } from '@/components/StyledComponents';
import { MEDIA_BREAKPOINTS } from '@/constants';
import ReactPlayer from 'react-player';

interface S3File {
  Key: string;
}

interface GalleryItemProps {
  file: S3File;
}

const GalleryItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  @media (max-width: ${MEDIA_BREAKPOINTS.smallScreen}) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  text-align: center;
`;

const VideoWrapper = styled.div`
  border-radius: 40px;
  overflow: hidden;
`;

const VideoStyle = styled.div`

  border: 2px solid transparent;
  border-image: linear-gradient(
    160deg,
    ${({ theme }) => theme.gradientColors[0]},
    ${({ theme }) => theme.gradientColors[1]}
  );
  overflow: hidden;
  border-image-slice: 1; /* Ensures border image stretches to fill border area */

  .react-player {
    margin-bottom: -10px;
  }
  width: ${({ theme }) => theme.sizes.small.largeImageWidth};
  height: ${({ theme }) => theme.sizes.small.largeImageHeight};

  @media (min-width: ${MEDIA_BREAKPOINTS.smallScreen}) {
    width: ${({ theme }) => theme.sizes.small.largeImageWidth};
    height: ${({ theme }) => theme.sizes.small.largeImageHeight};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.mediumScreen}) {
    width: ${({ theme }) => theme.sizes.medium.largeImageWidth};
    height: ${({ theme }) => theme.sizes.medium.largeImageHeight};
  }

  @media (min-width: ${MEDIA_BREAKPOINTS.largeScreen}) {
    width: ${({ theme }) => theme.sizes.large.largeImageWidth};
    height: ${({ theme }) => theme.sizes.large.largeImageHeight};
  }

`;

const isImage = (fileName: string): boolean =>
  /\.(jpg|jpeg|png|gif)$/i.test(fileName);
const isVideo = (fileName: string): boolean =>
  /\.(mp4|webm|ogg)$/i.test(fileName);

const extractFileNameForAlt = (filePath: string): string => {
  const name = filePath.split('/').pop(); // Extract the name part after the last '/'
  if (!name) return 'Gallery item'; // Fallback in case of an unexpected file path format
  return name.split('.').slice(0, -1).join('.'); // Remove the extension
};

const GalleryItem: React.FC<GalleryItemProps> = ({ file }) => {
  return (
    <GalleryItemContainer>
      {isImage(file.Key) && (
        <ImageWrapper>
          <LargeImage
            src={file.Key}
            alt={extractFileNameForAlt(file.Key)} // Use the extracted file name here
            imageType="largeImage" // Specify the image type here
            gradientAngle="120deg" // Adjust the gradient angle if needed
          />
        </ImageWrapper>
      )}
      {isVideo(file.Key) && (
        <VideoWrapper>
          <VideoStyle>
            <ReactPlayer
              url={file.Key}
              controls
              width="100%"
              height="100%"
              className="react-player"
            />
            </VideoStyle>
        </VideoWrapper>
      )}
    </GalleryItemContainer>
  );
};

export default React.memo(GalleryItem);
