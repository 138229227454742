import React from 'react';
import styled from 'styled-components';

import { SmallImage } from '@/components/StyledComponents';

interface Album {
  albumName: string;
  firstImageKey: string | null;
}

const AlbumButton = styled.button`
  margin: 2px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.textColor};
  font-size: ${({ theme }) => theme.sizes.small.description};
  font-family: ${({ theme }) => theme.sizes.fonts.rubikBoldItalic};
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow:
    -1px -2px 0 ${({ theme }) => theme.shadowDarkColor},
    1px -2px 0 ${({ theme }) => theme.shadowDarkColor},
    -1px 1px 0 ${({ theme }) => theme.shadowDarkColor},
    1px 1px 0 ${({ theme }) => theme.shadowDarkColor};
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
interface StoryImageProps {
  $isSelected: boolean;
}
const StoryImage = styled(SmallImage).attrs(
  ({ isSelected, ...rest }) => rest,
)<StoryImageProps>`
  border-radius: 50%;
  border: 3px solid
    ${({ theme, $isSelected }) =>
      $isSelected ? theme.MenuItemSelectedColor : theme.primaryColor};
`;

const Title = styled.div`
  font-size: 15px;
  padding-top: 10px;
`;

interface AlbumItemProps {
  album: Album;
  selectAlbum: (albumName: string) => void;
  isSelected: boolean;
}

const AlbumItem: React.FC<AlbumItemProps> = ({
  album,
  selectAlbum,
  isSelected,
}) => {
  
  return (
    <AlbumButton onClick={() => selectAlbum(album.albumName)}>
      {album.firstImageKey && (
        <ImageContainer>
          <StoryImage
            src={`${album.firstImageKey}`}
            alt={`${album.albumName}`}
            $isSelected={isSelected}
          />
        </ImageContainer>
      )}
      <Title>{album.albumName}</Title>
    </AlbumButton>
  );
};

export default AlbumItem;
