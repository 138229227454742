import React, { useEffect, useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import AlbumsCarousel from './AlbumsCarousel';
import GalleryCarousel from './GalleryCarousel';
import { Title, LoadingIndicator, ErrorMessage } from '../StyledComponents';
import { albumsOrder, SECTION_TWO_TITLE } from '@/constants';

const apiBaseUrl = process.env.REACT_APP_API_URL;

export interface S3File {
  Key: string;
}

export interface Album {
  albumName: string;
  firstImageKey: string | null;
}

// Styled components
const GalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AlbumsWrapper = styled.div`
  margin: 10px;
`;

const fetchAlbums = async (): Promise<Album[]> => {
  const response = await fetch(`${apiBaseUrl}/api/albums?cacheBust=${Date.now()}`);
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error('Network response was not ok');
  }

  const contentType = response.headers.get('content-type');
  
  if (contentType && contentType.includes('application/json')) {
    try {
      const albums: Album[] = await response.json();
      const orderedAlbums: Album[] = albumsOrder
        .map(order => albums.find((album: Album) => album.albumName === order))
        .filter(Boolean) as Album[];
      return orderedAlbums;
    } catch (error) {
      throw new Error('Failed to parse JSON');
    }
  } else {
    const errorText = await response.text();
    throw new Error('Expected JSON response but received:');
  }
};


const fetchImages = async (albumName: string) => {
  const response = await fetch(
    `${apiBaseUrl}/api/images/${encodeURIComponent(albumName)}`,
  );
  if (!response.ok) {
      throw new Error('Network response was not ok');
  }
  const imageKeys: string[] = await response.json();
  const imageFiles: S3File[] = imageKeys.map(key => {
      return { Key: key };
  });
  return imageFiles;
};


const preloadAssets = (files: S3File[]) => {
  files.forEach(file => {
    if (
      file.Key.endsWith('.mp4') ||
      file.Key.endsWith('.webm') ||
      file.Key.endsWith('.ogg')
    ) {
      const video = document.createElement('video');
      video.src = file.Key;
    } else {
      const img = new Image();
      img.src = file.Key;
    }
  });
};

const Gallery: React.FC = () => {
  const [selectedAlbum, setSelectedAlbum] = useState<string>('חתונות');

  const { data: albumsData, error: albumsError } = useQuery(
    'albums',
    fetchAlbums,
    {
      retry: false,
    },
  );

  const {
    data: filesData,
    isLoading: isLoadingFiles,
    error: filesError,
  } = useQuery(['images', selectedAlbum], () => fetchImages(selectedAlbum), {
    enabled: !!selectedAlbum,
    retry: false,
  });

  useEffect(() => {
    if (albumsData && albumsData.length > 0) {
      setSelectedAlbum(albumsData[0].albumName);
    }
  }, [albumsData]);

  useEffect(() => {
    if (filesData) {
      preloadAssets(filesData);
    }
  }, [filesData]);

  const selectAlbum = useCallback((albumName: string) => {
    setSelectedAlbum(albumName);
  }, []);
console.log('ELIOR MOR')
  return (
    <GalleryWrapper>
      <Title>{SECTION_TWO_TITLE}</Title>
      <AlbumsWrapper>
        {albumsError ? (
          <ErrorMessage message="Failed to load albums. Please try again later." />
        ) : (
          albumsData && (
            <AlbumsCarousel albums={albumsData} selectAlbum={selectAlbum} />
          )
        )}
      </AlbumsWrapper>
      {isLoadingFiles && <LoadingIndicator />}

      {filesError ? <ErrorMessage message="Error loading files." /> : null}

      {filesData && !isLoadingFiles && !filesError && (
        <GalleryCarousel files={filesData} selectedAlbum={selectedAlbum} />
      )}
    </GalleryWrapper>
  );
};

export default Gallery;

